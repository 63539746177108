import { navigate } from 'gatsby';
import React, { useEffect } from 'react'
import Layout from '../components/Layout';
import PricingForm from '../components/PricingForm'
import { Seo } from '../components/seo';

const PricingSubmit = ({ location }) => {
    const data = { ...location.state }

    useEffect(() => {
        if (!data.price) {
            navigate('/pricing')
        }
    }, [data.price]);


    return (
      <>
        <Seo
          title='Pricing'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />

        <Layout>
          <main id='main'>
            <PricingForm {...data} />
          </main>
        </Layout>
      </>
    );

}

export default PricingSubmit;

