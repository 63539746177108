import axios from 'axios';
import React, { useState } from 'react'

const PricingForm = ({ final, price }) => {
  const [gstIn, setGstIn] = useState(false);

  const gstInChanged = () => {
    setGstIn(!gstIn);
  };

  const [enableBtn, setEnableBtn] = useState(true);
  const [GstBtn, setGstBtn] = useState(false);
  const enable = () => {
    setEnableBtn(!enableBtn);
  };
  const total = () => {
    const sumValue = final;
    const gst = 179.82;
    const totalValue = parseInt(sumValue) + gst;
    return totalValue;
  };
  const pay = total();

  //Mail
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);

  let startData = {
    fname: '',
    lname: '',
    email: '',
    countryCode: '',
    phone: '',
    state: '',
    gst_in: '',
    comp_name: '',
    comp_address: '',
    pin_code: '',
  };

  const [formData, setFormData] = useState(startData);

  const handleInput = (event) => {
    const latestData = { ...formData };
    latestData[event.target.id] = event.target.value;
    setFormData(latestData);
  };

  const baseURL = 'https://app.todayfilings.com/api/pricing-mail';
  // const baseURL = "http://127.0.0.1:8000/api/pricing-mail";
  const SubmitForm = (event) => {
    event.preventDefault();
    setSpinner(true);
    setEnableBtn(true);
    setGstBtn(true);
    const data = new FormData(event.target);
    data.append('final', final);
    data.append('pay', pay);
    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(baseURL, data, axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setSpinner(false);
            setEnableBtn(true);
            setGstBtn(false);
          }, 3000);
          setFormData(startData);
        } else {
          alert('no');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const countryCode = [
    {
      value: '+91',
      codeValue: `IN (+91)`,
    },
    {
      value: '+1',
      codeValue: `USA (+1)`,
    },
    {
      value: '+44',
      codeValue: `UK (+44)`,
    },
    {
      value: '+61',
      codeValue: `AU (+61)`,
    },
    {
      value: '+86',
      codeValue: `CN (+86)`,
    },
    {
      value: '+60',
      codeValue: `MY (+60)`,
    },
    {
      value: '+960',
      codeValue: `MV (+960)`,
    },
    {
      value: '+64',
      codeValue: `NZ (+64)`,
    },
    {
      value: '+7',
      codeValue: `RU (+7)`,
    },
    {
      value: '+27',
      codeValue: `ZA (+27)`,
    },
    {
      value: '+34',
      codeValue: `ES (+34)`,
    },
    {
      value: '+94',
      codeValue: `LK (+94)`,
    },
  ];

  const states = [
    {
      value: '',
      code: `Select State`,
    },
    {
      value: '35',
      code: `ANDAMAN and NICOBAR ISLANDS`,
    },
    {
      value: '28',
      code: `ANDHRA PRADESH`,
    },
    {
      value: '12',
      code: `ARUNACHAL PRADESH`,
    },
    {
      value: '18',
      code: `ASSAM`,
    },
    {
      value: '10',
      code: `BIHAR`,
    },
    {
      value: '4',
      code: `CHANDIGARH`,
    },
    {
      value: '22',
      code: `CHHATTISGARH`,
    },
    {
      value: '26',
      code: `DADRA and NAGAR HAVELI`,
    },
    {
      value: '25',
      code: `DAMAN and DIU`,
    },
    {
      value: '30',
      code: `GOA`,
    },
    {
      value: '24',
      code: `GUJARAT`,
    },
    {
      value: '6',
      code: `HARYANA`,
    },
    {
      value: '2',
      code: `HIMACHAL PRADESH`,
    },
    {
      value: '1',
      code: `JAMMU and KASHMIR`,
    },
    {
      value: '20',
      code: `JHARKHAND`,
    },
    {
      value: '29',
      code: `KARNATAKA`,
    },
    {
      value: '32',
      code: `KERALA`,
    },
    {
      value: '31',
      code: `LAKSHADWEEP`,
    },
    {
      value: '23',
      code: `MADHYA PRADESH`,
    },
    {
      value: '27',
      code: `MAHARASHTRA`,
    },
    {
      value: '14',
      code: `MANIPUR`,
    },
    {
      value: '17',
      code: `MEGHALAYA`,
    },
    {
      value: '15',
      code: `MIZORAM`,
    },
    {
      value: '13',
      code: `NAGALAND`,
    },
    {
      value: '7',
      code: `DELHI`,
    },
    {
      value: '21',
      code: `ORISSA`,
    },
    {
      value: '34',
      code: `PUDUCHERRY`,
    },
    {
      value: '3',
      code: `PUNJAB`,
    },
    {
      value: '8',
      code: `RAJASTHAN`,
    },
    {
      value: '11',
      code: `SIKKIM`,
    },
    {
      value: '33',
      code: `TAMIL NADU`,
    },
    {
      value: '36',
      code: `TELANGANA`,
    },
    {
      value: '16',
      code: `TRIPURA`,
    },
    {
      value: '9',
      code: `UTTAR PRADESH`,
    },
    {
      value: '5',
      code: `UTTARAKHAND`,
    },
  ];

  return (
    <section className='pricing-form'>
      <div className='container'>
        <div className='row'>
          <div className='section-header'>
            <h2>Your Order Summery</h2>
            <div className='bar'></div>
          </div>
          <div className='row pricing'>
            <div className='row'>
              <div className='col-md-12'>
                <form
                  action=''
                  className='price-form'
                  method='POST'
                  onSubmit={SubmitForm}
                >
                  <div className='row pri-form'>
                    <div className='col-md-6 col-sm-12 p-form'>
                      <div className='row '>
                        <div className='col-md-1'></div>
                        <div className='col-md-10 '>
                          <div className='row'>
                            <h6>Contact Information</h6>
                            <hr />
                            <div className=' col-md-6 mb-3 mt-4'>
                              <label htmlFor='form-label'>
                                First Name
                                <span className='imp text-danger'>*</span>
                              </label>
                              <input
                                type='name'
                                className='form-control'
                                id='fname'
                                placeholder='Enter First Name'
                                name='fname'
                                required
                                autoComplete='off'
                                value={formData.fname}
                                onChange={handleInput}
                              />
                            </div>
                            <div className=' col-md-6 mb-3 mt-4'>
                              <label htmlFor='form-label'>Last Name</label>
                              <input
                                type='name'
                                className='form-control'
                                id='lname'
                                placeholder='Enter Last Name'
                                name='lname'
                                autoComplete='off'
                                value={formData.lname}
                                onChange={handleInput}
                              />
                            </div>
                          </div>

                          <div className='col-md-12 mb-3 mt-3'>
                            <label htmlFor='form-label'>
                              Email<span className='imp text-danger'>*</span>
                            </label>
                            <input
                              type='email'
                              className='form-control'
                              id='email'
                              placeholder='Enter your email'
                              name='email'
                              required
                              autoComplete='off'
                              value={formData.email}
                              onChange={handleInput}
                            />
                          </div>

                          <div className='row'>
                            <div className=' col-md-4 mb-3 mt-4'>
                              <label htmlFor='form-label'>Country Code</label>
                              <div className='form-group'>
                                <select
                                  name='countryCode'
                                  id='countryCode'
                                  className='form-control'
                                  defaultValue='+91'
                                  autoComplete='off'
                                  value={formData.countryCode}
                                  onChange={handleInput}
                                >
                                  {countryCode.map((code, index) => (
                                    <option value={code.value} key={index}>
                                      {code.codeValue}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className='col-md-8 mb-3 mt-4'>
                              <label htmlFor='form-label'>
                                Contact No
                                <span className='imp text-danger'>*</span>
                              </label>
                              <input
                                type='phone'
                                className='form-control'
                                id='phone'
                                placeholder='Enter Phone Number'
                                name='phone'
                                required
                                autoComplete='off'
                                value={formData.phone}
                                onChange={handleInput}
                              />
                            </div>
                          </div>
                          <div className=' col-md-12 mb-3 mt-4'>
                            <label htmlFor='form-label'>
                              State<span className='imp text-danger'>*</span>
                            </label>
                            <div className='form-group'>
                              <select
                                name='state'
                                required='required'
                                id='state'
                                className='form-control'
                                autoComplete='off'
                                value={formData.state}
                                onChange={handleInput}
                              >
                                {states.map((statess, index) => (
                                  <option value={statess.code} key={index}>
                                    {statess.code}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <hr />
                          <div className='row '>
                            <div className='gst-in'>
                              <h4>Do you have a GSTIN?</h4>
                              <input
                                type='checkbox'
                                name='gst_if'
                                value='1'
                                id='switch1'
                                onChange={gstInChanged}
                                disabled={GstBtn ? 'disabled' : ''}
                              />
                              <label htmlFor='switch1' className='switch1'>
                                <span
                                  className={`${
                                    gstIn ? 'text-muted' : 'text-dark'
                                  }`}
                                >
                                  No
                                </span>
                                <div className='switch'>
                                  <div className='bar'></div>
                                </div>
                                <span
                                  className={`${
                                    gstIn ? 'text-dark' : 'text-muted'
                                  }`}
                                >
                                  Yes
                                </span>
                              </label>
                            </div>
                            {gstIn ? (
                              <div className='row gstin-details'>
                                <div className='col-md-12 mb-3 mt-4'>
                                  <label htmlFor='form-label'>
                                    GSTIN
                                    <span className='imp text-danger'>*</span>
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='gst_in'
                                    placeholder='Enter your company GSTIN'
                                    name='gst_in'
                                    required
                                    autoComplete='off'
                                    value={formData.gst_in}
                                    onChange={handleInput}
                                  />
                                </div>
                                <div className=' col-md-12 mb-3 mt-4'>
                                  <label htmlFor='form-label'>
                                    Company Name(as per GSTIN)
                                    <span className='imp text-danger'>*</span>
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='comp_name'
                                    placeholder='Enter Last Name'
                                    name='comp_name'
                                    autoComplete='off'
                                    value={formData.comp_name}
                                    onChange={handleInput}
                                  />
                                </div>

                                <div className=' col-md-12 mb-3 mt-4'>
                                  <label htmlFor='form-label'>
                                    Company Address(as per GSTIN)
                                  </label>
                                  <input
                                    type='address'
                                    className='form-control'
                                    id='comp_address'
                                    placeholder='Enter Last Name'
                                    name='comp_address'
                                    autoComplete='off'
                                    value={formData.comp_address}
                                    onChange={handleInput}
                                  />
                                </div>
                                <div className=' col-md-12 mb-3 mt-4'>
                                  <label htmlFor='form-label'>Pin Code</label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    id='pin_code'
                                    placeholder='Enter Last Name'
                                    name='pin_code'
                                    autoComplete='off'
                                    value={formData.pin_code}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 amount'>
                      <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-md-10'>
                          <h6>Amount Pay</h6>
                          <hr />

                          <div className='plan-amount'>
                            <p>Plan Amount</p>
                            <p>
                              <span className='ori-price'>₹{price}</span>
                              <span className='price-value1'>₹{final}</span>
                            </p>
                          </div>
                          <div className='plan-amount'>
                            <p>Goods and Services Tax @ 18% </p>
                            <p>
                              <span>₹179.82</span>
                            </p>
                          </div>
                          <hr />

                          <div className='plan-amount'>
                            <p>Total</p>
                            <p>
                              <strong>
                                <span>₹{Math.round(pay)}</span>
                              </strong>
                            </p>
                          </div>
                          <hr />
                          <p>
                            Service Providers GSTIN:
                            <strong>1dd23456789sdf0</strong>
                          </p>

                          <hr />
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className='row privacy-check'>
                            <div className='col-md-1'>
                              <input
                                type='checkbox'
                                value='1'
                                checked={enableBtn ? '' : 'checked'}
                                onClick={enable}
                              />
                            </div>
                            <div className='col-md-11'>
                              <p>
                                I accept TODAYFILINGS Terms and Privacy Policy
                              </p>
                            </div>
                          </div>

                          <button
                            type='submit'
                            className='price-btn btn btn-primary'
                            disabled={enableBtn}
                          >
                            {spinner ? (
                              <span
                                className='spinner-border spinner-border-sm'
                                role='status'
                                aria-hidden='true'
                              ></span>
                            ) : (
                              <span
                                className='text-white'
                                style={{ marginRight: 10 }}
                              >
                                <i className='bi bi-send'></i>
                              </span>
                            )}
                            Proceed to Payment
                          </button>
                          {show ? (
                            <p
                              id='success-text'
                              className='success-text text-center text-primary'
                            >
                              Pricing Submit Successfully
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingForm;